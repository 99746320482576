// /var/www/mypostdigest/pages/_app.tsx

import '../styles/globals.css'; // Assurez-vous que ce chemin pointe vers votre fichier global de styles
import type { AppProps } from 'next/app';

function MyApp({ Component, pageProps }: AppProps) {
  return <Component {...pageProps} />;
}

export default MyApp;

